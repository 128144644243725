import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
import { between, transparentize } from 'polished';
import { FaCheck, FaLongArrowAltRight } from 'react-icons/fa';

import Page from '../components/page/page';
import HeaderSection from '../components/header-section';
import HeaderFront from '../components/header-front';
import Navigation from '../components/navigation';
import HeaderHeading from '../components/header-heading';
import HeaderDescription from '../components/header-description';
import SEO from '../components/seo';
import Container from '../components/container';
import Testimonial from '../components/testimonial';

const StyledHeaderSection = styled(HeaderSection)`
	min-height: ${between('280px', '520px')};
`;

const Pricing = styled.div`
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(290px, 1fr));
	grid-gap: 20px ${between('60px', '1px')};;
	margin-top: ${between('1px', '-110px')};
	margin-bottom: ${between('1px', '30px')};
	position: relative;
	z-index: 100;
`;

const Option = styled.div`
	background: ${props => props.theme.colors.white};
	border-radius: ${props => props.theme.border_radius};
	border-top: solid 5px hsl(60, 0%, 75%);
	position: relative;
	padding: 15px 20px;
	text-align: center;
	color: ${props => props.theme.colors.warm_grey_800};
	box-shadow: 0 1px 50px ${props => props.theme.colors.warm_grey_800 && transparentize(0.9, props.theme.colors.warm_grey_800)},
		0 2px 15px ${props => props.theme.colors.warm_grey_800 && transparentize(0.9, props.theme.colors.warm_grey_800)};
	transition: all 0.3s;

	.period {
		display: block;
		margin-top: -10px;
		font-size: 14px;
	}

	ul {
		margin: 45px 0 80px;
		list-style-type: none;
		text-align: left;

		li {
			padding: 10px 0;
			font-size: 14px;

			& + li {
				margin-top: 7px;
			}

			&:nth-child(even) {
				background: linear-gradient(to right, transparent 5%, ${props => props.theme.colors.warm_grey_1000 && transparentize(0.97, props.theme.colors.warm_grey_1000)} 50%, transparent 95%);
			}

			svg {
				opacity: 0.5;
				margin-right: 5px;
				margin-bottom: -1px;
			}

			.soon {
				background: ${props => props.theme.colors.golden_800};
				color: ${props => props.theme.colors.golden_400};
				font-size: 12px;
				margin-left: 5px;
				padding: 2px 10px;
				border-radius: 10px;
			}
		}
	}

	.cta {
		width: 100%;
		text-align: center;
		padding: 23px 0;
		left: 0;
		color: ${props => props.theme.colors.warm_grey_600};
		text-transform: uppercase;
		letter-spacing: 1px;
		font-size: 14px;
		bottom: 10px;
		position: absolute;
		font-weight: 700;
	}

	&.diamond {
		background: hsla(277, 100%, 98%);
		background: linear-gradient(to bottom, hsla(277, 100%, 98%) 0%, hsl(238, 100%, 96%) 100%);
		border-color: hsl(265, 67%, 86%);

		.soon {
			background: hsl(265, 67%, 86%);
			color: hsl(265, 44%, 30%);
		}

		li svg {
			fill: hsl(265, 44%, 30%);
		}

		.cta {
			color: hsl(265, 44%, 30%);
		}
	}

	&.golden {
		background: ${props => props.theme.colors.golden_400};
		background: linear-gradient(to bottom, hsl(37, 100%, 88%) 0%, hsl(37, 100%, 83%) 100%);
		border-color: hsl(36, 59%, 70%);
		z-index: 10;

		.soon {
			background: hsl(36, 59%, 70%);
			color: hsl(37, 91%, 15%);
		}

		li svg {
			fill: hsl(37, 91%, 15%);
		}

		.cta {
			color: hsl(37, 91%, 15%);
		}

		@media (min-width: ${props => props.theme.breakpoints.md}) {
			margin: -30px -10px;
		}
	}

	&.silver {
		background: hsla(37, 35%, 99%);
		background: linear-gradient(to bottom, hsla(37, 35%, 99%) 0%, hsl(37, 6%, 93%) 100%);
	}
`;

const Name = styled.h2`
	font-size: 14px;
`;

const Price = styled.span`
	font-size: 48px;
	display: inline-block;
	margin: 19px 0 23px 0;
	font-family: 'Playfair Display', serif;
	font-weight: 700;

	.strike {
		font-size: 26px;
		opacity: 0.8;
		background: linear-gradient(-20deg, transparent 40.75%, currentColor 42.5%, currentColor 45.5%, transparent 47.25%);
	}

	.currency {
		font-size: 18px;
	}
`;

const AdditionalSection = styled.section`
	background: ${props => props.theme.colors.warm_grey_100};
	position: relative;
	padding-top: ${between('15px', '80px')};
	padding-bottom: ${between('30px', '40px')};
`;

const FAQ = styled(Container)`
	max-width: 768px;

	p {
		font-size: ${between('14px', '15px')};
		line-height: ${between('20px', '26px')};

		& + h3 {
			margin-top: ${between('50px', '60px')};
		}
	}
`;

const Center = styled(Container)`
	text-align: center;
	margin-bottom: ${between('10px', '60px')};
`;

const PricingPage = () => (
	<Page>
		<SEO title="Cennik" description="Przejrzyste zasady, bez niespodzianek. Pokaż czym się zajmujesz i dodaj swoją ofertę już teraz!" canonical="cennik" />
		<StyledHeaderSection hero="/hero-profile.jpg">
			<HeaderFront>
				<Navigation />
				<Container>
					<HeaderHeading>Cennik</HeaderHeading>
					<HeaderDescription>Przejrzyste zasady, bez niespodzianek</HeaderDescription>
				</Container>
			</HeaderFront>
		</StyledHeaderSection>

		<Container>
			<Pricing>
				<Option className="diamond">
					<Name>Pakiet Diamentowy – dla wymagających</Name>
					<Price>
						<span className="strike">149</span> 99 <span className="currency">zł</span>
					</Price>
					<span className="period">rocznie za 1 ofertę</span>

					<ul>
						<li><FaCheck /> Do 15 zdjęć w galerii</li>
						<li><FaCheck /> Brak ograniczeń w długości opisu</li>
						<li><FaCheck /> Kontakt przez Messengera</li>
						<li><FaCheck /> Kontakt przez WhatsAppa</li>
						<li><FaCheck /> Czołowe miejsce w wynikach wyszukiwania</li>
						<li><FaCheck /> Oceny klientów <span className="soon" tooltip="Funkcja w budowie">wkrótce</span></li>
						<li><FaCheck /> Statystyki odwiedzin <span className="soon" tooltip="Funkcja w budowie">wkrótce</span></li>
						<li><FaCheck /> Promocja w mediach społecznościowych</li>
						<li><FaCheck /> Ukryta sekcja podobnych ogłoszeń pod ofertą</li>
						<li><FaCheck /> Indywidualny cennik przy wielu ofertach</li>
					</ul>

					<Link className="cta" to="/dodaj-oferte/">
						Dodaj ofertę <FaLongArrowAltRight />
					</Link>
				</Option>

				<Option className="golden">
					<Name>Pakiet Złoty – dla profesjonalistów</Name>
					<Price>
						<span className="strike">69</span> 49 <span className="currency">zł</span>
					</Price>
					<span className="period">rocznie za 1 ofertę</span>

					<ul>
						<li><FaCheck /> <b>Za darmo przez 30 dni</b></li>
						<li><FaCheck /> Do 10 zdjęć w galerii</li>
						<li><FaCheck /> Brak ograniczeń w długości opisu</li>
						<li><FaCheck /> Kontakt przez Messengera</li>
						<li><FaCheck /> Kontakt przez WhatsAppa</li>
						<li><FaCheck /> Wyższa pozycja w wynikach wyszukiwania</li>
						<li><FaCheck /> Oceny klientów <span className="soon" tooltip="Funkcja w budowie">wkrótce</span></li>
						<li><FaCheck /> Indywidualny cennik przy wielu ofertach</li>
					</ul>

					<Link className="cta" to="/dodaj-oferte/">
						Dodaj ofertę <FaLongArrowAltRight />
					</Link>
				</Option>

				<Option className="silver">
					<Name>Pakiet Srebrny – dla hobbystów</Name>
					<Price>
						<span className="strike">19</span> 0 <span className="currency">zł</span>
					</Price>

					<ul>
						<li><FaCheck /> Do 5 zdjęć w galerii</li>
						<li><FaCheck /> Brak ograniczeń w długości opisu</li>
						<li><FaCheck /> Kontakt przez Messengera</li>
						<li><FaCheck /> Kontakt przez WhatsAppa</li>
						<li><FaCheck /> Standardowa pozycja w wynikach wyszukiwania</li>
					</ul>

					<Link className="cta" to="/dodaj-oferte/">
						Dodaj ofertę <FaLongArrowAltRight />
					</Link>
				</Option>
			</Pricing>
		</Container>

		<AdditionalSection className="slant--top slant--bottom">
			<Container>
				<Testimonial />
			</Container>
		</AdditionalSection>

		<FAQ>
			<h3>Czy mogę bezpłatnie dodać ofertę?</h3>
			<p><b>Tak!</b> Ofertę w podstawowej formie można dodać bezpłatnie. Korzystanie z dodatkowych możliwości, jak np. dodanie większej liczby zdjęć lub pojawienie się na wyższej pozycji w wynikach wyszukiwania wymaga płatnego pakietu.</p>

			{/* <h3>Czy mogę zapłacić kartą?</h3>
			<p><b>Tak!</b> W celu zapewnienia bezpieczeństwa oraz uproszczenia procesu dokonywania płatności, akceptujemy jedynie karty płatnicze (kredytowe oraz debetowe).</p>

			<h3>Czy płatność jest jednorazowa?</h3>
			<p><b>Nie</b>, płatności odbywają się w modelu abonamentowym (tzw. <i>subskrypcja</i>). Po wybraniu odpowiedniego pakietu i wprowadzeniu danych, system co miesiąc automatycznie pobierze ustaloną kwotę.</p>

			<h3>Czy mogę anulować subskrypcję?</h3>
			<p><b>Tak!</b> Subskrypcję (abonament) można anulować w dowolnej chwili i żadne dodatkowe opłaty nie będą naliczone. Zmieni się jednak pakiet oferty (srebrny) i obowiązywać będą związane z nim limity.</p>

			<h3>Czy mogę ponownie wznowić subskrypcję?</h3>
			<p><b>Tak!</b> Należy wówczas ponownie wybrać pakiet i podać dane do płatności.</p> */}
		</FAQ>

		<AdditionalSection className="slant--top slant--bottom">
			<Center>
				<h2>Nie przegap nowych zleceń</h2>
				<Link to="/dodaj-oferte/">
					Dodaj ofertę <FaLongArrowAltRight />
				</Link>
			</Center>
		</AdditionalSection>
	</Page>
);

export default PricingPage;
